import { Slot } from "@radix-ui/react-slot";
import { VariantProps, cva } from "class-variance-authority";
import { cn } from "../utils";

export const cardVariants = cva("relative text-foreground bg-card overflow-hidden block group", {
  variants: {
    variant: {
      tile: "ring-1 ring-stroke shadow shadow-black/5",
      surface: "ring-1 ring-stroke",
      warning: "ring-1 ring-warning",
      success: "bg-green-10 shadow dark:bg-green-30",
      info: "bg-primary/10",
      orange: "bg-orange-10 shadow dark:bg-orange-300",
      secondary: "bg-subtle",
      "secondary-tile": "bg-subtle ring-1 ring-stroke/5 shadow-md shadow-black/5",
    },
    divided: {
      true: "divide-y divide-stroke/10",
    },
    hoverable: {
      true: "transition-colors",
    },
    rounded: {
      true: "rounded-lg",
      false: "rounded-none",
    },
  },
  compoundVariants: [{ variant: "tile", hoverable: true, class: "hover:ring-stroke/20" }],
  defaultVariants: {
    variant: "tile",
    rounded: true,
  },
});

interface CardProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof cardVariants> {
  asChild?: boolean;
}

export const Card = ({ variant, divided, asChild, className, rounded, ...props }: CardProps) => {
  const Comp = asChild ? Slot : "div";
  return <Comp className={cn(cardVariants({ variant, divided, hoverable: asChild, rounded }), className)} {...props} />;
};

const contentVariants = cva("relative z-10", {
  variants: {
    padding: {
      md: "p-4",
      lg: "p-6",
    },
  },
  defaultVariants: {
    padding: "md",
  },
});

interface CardContentProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof contentVariants> {}

export const CardContent = ({ padding, className, ...props }: CardContentProps) => {
  return <div className={cn(contentVariants({ padding }), className)} {...props} />;
};

export const CardGradient = ({ className, children }: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={cn(
        "absolute -inset-px z-0 select-none bg-gradient-to-br from-[#4E4AFF] via-[#EB4AFF] to-[#F95644]",
        className,
      )}
    >
      <div className="bg-background absolute inset-0 -m-px rounded-lg">{children}</div>
    </div>
  );
};
